@media(max-height:850px) {
    section#card_main {
        padding: 60px 0;
    }

    .olivia-img {
        border-radius: 240px 40px 40px 40px;
        max-width: 100%;
    }
}

@media(max-width:1700px) {
    section#card_main {
        margin: 0 0 0 60px;
    }
}

@media(max-width:1600px) {
    .imgBox video {
        height: 100%;
        width: 390px;
    }
    .imgBox {
        height: 100%;
        width: 390px;
    }

    .olivia-img {
        border-radius: 40px 40px 40px 40px;
        max-width: 100%;
    }

    .hire_me {
        width: 350px;
    }

    .olivia_moswa {
        font-size: 40px;
    }

    .designer {
        margin-bottom: 18px;
    }

    .bio_contain {
        padding: 10px 20px 13px 20px;
    }

    .olivia_bio {
        border-radius: 40px 40px 40px 40px;
        margin-bottom: 30px;
    }

    section#card_main {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 60px 0;
        height: 100%;
        margin: 0 90px 0 155px;
    }

    .right_card_main {
        border-radius: 40px 40px 40px 40px;
    }

    #icetab-content {
        transform: translateX(0px);
    }

    .ul-header {
        position: fixed;
        right: 40px;
        top: 40px;
    }

    .sidebar {
        width: 78px;
        background: var(--card-background);
        transition: all 0.5s ease;
        border-radius: 30px;
        margin-left: 0px;
        position: fixed;
        left: 40px;
        top: 0;
        transform: translate(0%, 30%);
    }
}

@media(max-width:1050px) {
    .sidebar {
        display: none;
    }

    .social_media {
        display: none;
    }

    .toggle {
        transform: translate(60%, 0px);
    }

    section#card_main {
        margin: 0 auto;
    }

}

@media(max-width:991px) {
    .circular_text {
        padding: 2px;
        width: 150px;
    }

    .cursor {
        display: none;
    }

    section#card_main {
        padding: 80px 12px 40px 12px;
    }

    .olivia-img {
        max-width: 100%;
    }

    .corporate_main {
        padding: 15px 0;
    }

    #close {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        font-size: 23px;
    }

    .pop-up-video {
        width: 100%;
    }

    .corporate_sub {
        padding-bottom: 15px;
    }

    .blandit_text {
        text-align: center;
    }
}

@media(max-width:980px) {
    .overlay .content2 {
        height: 90vh;
        max-width: 90%;
        width: 90%;
    }

    .modal {
        width: 90%;
        max-width: 90%;
        height: 90vh;
        top: 40px;
    }
}

@media(max-width:820px) {
    .right_card_main {
        width: 100%;
    }

    .moswa {
        font-size: 40px;
        line-height: 58px;
        padding: 15px 0;
    }

    .passionate {
        font-size: 18px;
        line-height: 30px;
        margin: 0;
        padding-bottom: 20px;
    }

    .circular_text {
        width: 140px;
        height: 140px;
        padding: 2px;
    }

    .download_cv {
        font-size: 18px;
    }

    .counter2 {
        font-size: 40px;
        line-height: 50px;
    }

    .envato_group_main {
        padding-bottom: 10px;
    }

    .services_box_main {
        gap: 20px;
    }

    .pricing_group {
        padding-top: 40px;
    }

    .lan_skill {
        text-align: center;
    }

    .hard_skill {
        padding-top: 30px;
        text-align: center;
    }

    .hard_skill_group {
        width: 120px;
        height: 170px
    }

    .skill_circle_main {
        justify-items: center;
        gap: 20px;
        padding-top: 20px;
    }

    .content_main {
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }
}

@media(max-width:767px) {
    svg text {
        font-size: 140px;
    }

    .toggle {
        position: fixed;
        top: 20px;
        left: 0px;
        transform: translate(50%, 0px);
        width: 50px;
        height: 50px;
    }

    .project_sub_images {
        margin: 10px 0;
    }

    .pop-up-footer {
        padding-top: 20px;
    }

    .corporate_sub {
        padding-bottom: 10px;
    }

    .project_name {
        padding: 0px 0 10px 0;
    }

    .content3 {
        padding: 20px;
    }

    #close {
        top: 10px;
        right: 10px;
    }

    .ul-header {
        position: fixed;
        right: 30px;
        top: 30px;
    }

    .moswa {
        font-size: 37px;
        line-height: 50px;
        padding: 8px 0;
    }

    .counter2 {
        font-size: 37px;
        line-height: 45px;
    }

    .bachelor {
        font-size: 18px;
        line-height: 24px;
    }

    .university {
        line-height: 20px;
        padding-bottom: 10px;
    }

    .pricing_group {
        padding-top: 30px;
    }

    .basic_plain {
        font-size: 22px;
        line-height: 25px;
        padding-bottom: 10px;
    }

    .date {
        font-size: 18px;
        line-height: 22px;
    }

    .become {
        text-align: center;
    }

    .web_development {
        text-align: center;
    }

    .skill_circle_main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .circle-chart__percent {
        alignment-baseline: hanging;
        dominant-baseline: hanging;
        text-anchor: middle;
    }

    .blog-pop-up-list-main ul {
        padding: 0px 0 20px 17px;
    }

    .small_blog {
        padding: 15px
    }

    .viverra {
        padding: 20px 0 10px;
    }

    .logo_design {
        padding-bottom: 10px;
    }

    .eget {
        padding: 20px 0;
    }

    .graphic {
        padding-bottom: 15px;
    }

    .blog_pop_up {
        margin-bottom: 20px;
    }
}

@media(max-width:700px) {
    .blog_section_main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    .blog_img {
        background-position: top center;
        height: 350px;
    }

    .view_more {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        padding-top: 20px;
    }

    .moswa,
    .passionate {
        text-align: center;
    }

    .intro_main {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button_circle_group {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 30px;
    }

    .counter2 {
        text-align: center;
    }

    .about_counter {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    .experince {
        text-align: center;
    }

    .service_box {
        text-align: center;
        padding: 30px;
    }

    .ui_ux {
        font-size: 22px;
        padding: 10px 0 10px 0;
    }

    .ui_sub_text {
        margin: 0;
    }

    .content-details h3 {
        font-size: 20px;
    }

    .tabcontent {
        padding: 30px;
    }

    h3.moswa.touch {
        text-align: start;
    }

    .side_circle_ring,
    .education::before {
        display: none;
    }

    .small_yellow_border_main {
        padding-left: 0px;
        text-align: left;
    }

    .experience_main_text {
        padding-top: 15px;
    }

    .envato_group_main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column-reverse;
        gap: 20px;
    }

    .services_box_main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .plan_box_sub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

    .build {
        text-align: center;
    }

    .plan_box_sub2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 14px;
    }

    .content_main1 {
        display: grid;
        justify-items: center;
        width: 100%;
        height: 100%;
        gap: 20px;
    }

    .content_main2 {
        display: grid;
        gap: 20px;
        justify-items: center;
        width: 100%;
    }

    .content_main {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-direction: column;
    }

    .project_name,
    .project_name_sub,
    .copyright,
    .nunc,
    .viverra,
    .integer,
    .vel,
    .eget {
        text-align: center;
    }

    .line_client {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blog-pop-up-list li {
        line-height: 25px;
    }

    .view_more_main {
        padding-top: 20px;
    }

    .right_card_main {
        border-radius: 20px 20px 20px 20px;
    }

    .olivia_bio {
        border-radius: 20px 20px 20px 20px;
        margin-bottom: 20px !important;
    }

    .olivia-img {
        border-radius: 20px 20px 20px 20px;
    }

    .bio_contain {
        border-radius: 0px 0px 20px 20px
    }

    section#card_main {
        padding: 80px 12px 20px 12px;
    }

    .down_arrow {
        height: 36px;
    }

    .olivia_bio {
        margin-bottom: 20px;
    }
}

@media(max-width:645px) {
    .view_project {
        padding: 18px 0;
        width: 50%;
        margin-top: 30px;
    }

    .project_second {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        justify-items: center;
    }

    .project_sub_images {
        margin: 5px 0;
    }

    .created_by,
    .cuberto {
        padding: 5px 0;
    }

    .pop-up-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex-direction: column-reverse;
    }

    .share-btn {
        position: absolute;
        right: 40%;
        bottom: 85px;
        width: 60px;
    }

    .share-btn2 {
        position: absolute;
        bottom: 0% !important;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .circle-wrapper {
        position: relative;
        width: 90px;
        height: 90px;
        margin-top: 20px;
    }

    .close-btn {
        font-size: 14px;
    }

    #close {
        font-size: 25px;
    }

    .corporate_main {
        padding: 10px 0;
    }
}

@media(max-width:600px) {
    .moswa {
        font-size: 30px;
        line-height: 40px;
        padding: 8px 0;
    }

    .passionate {
        font-size: 17px;
        line-height: 26px;
        margin: 0;
        padding-bottom: 15px;
    }

    .circular_text {
        width: 110px;
        height: 110px;
    }

    .button_circle_group {
        gap: 20px;
    }

    .counter2 {
        font-size: 30px;
        margin: 0;
    }

    .experince {
        font-size: 17px;
        margin: 0;
    }

    .about_counter {
        gap: 15px;
    }

    .envato_group_main,
    .bachelor {
        gap: 15px;
        padding-bottom: 5px;
    }

    .education_space {
        margin-bottom: 20px;
    }

    .blog_img {
        height: 250px;
    }

    .skill_circle_main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .prev-arrow,
    .next-arrow,
    .slick-counter {
        display: none !important;
    }

    .testimonials_card {
        margin-bottom: 0px;
    }

    .placerat {
        text-align: left;
    }

    .slider_contain_main {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .collan {
        display: none !important;
    }

    .rating_main {
        padding: 15px 0 10px 0;
        display: grid;
        justify-items: center;
    }

    .blog_section_main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    iframe {
        margin: 15px 0;
        width: 100%;
        height: 220px;
        border-radius: 20px;
    }
}

@media(max-width:550px) {
    .imgBox video {
        height: 100%;
        width: 300px;
    }
    .imgBox {
        height: 100%;
        width: 300px;
    }

    .hire_me {
        width: 230px;
        padding: 13px 10px;
    }

    .bio_contain {
        width: calc(100% - 20px);
        bottom: 10px;
        left: 10px;
        padding: 10px;
    }

    .olivia_bio {
        padding: 10px;
    }

    .designer {
        margin-bottom: 10px;
        height: 20px;
        font-size: 16px;
    }

    .olivia_moswa {
        font-size: 30px;
        padding-bottom: 5px;
    }

    .hard_skill_counter {
        font-size: 20px;
    }

    .figma {
        width: 50%;
    }

    .overlay .content2 {
        padding: 14px;
    }

    .content3 {
        padding: 14px;
    }

    #close {
        top: 7px;
        right: 7px;
        width: 30px;
        height: 30px;
        font-size: 22px;
    }
}

@media(max-width:500px) {
    .view_project {
        width: 80%;
    }

    .ul-header a {
        font-size: 24px;
    }

    .tabcontent {
        padding: 40px 12px;
    }

    .moswa {
        font-size: 28px;
        line-height: 37px;
        padding: 8px 0;
    }

    .container1 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    .message2 {
        height: 100px;
    }

    .send_message {
        font-size: 18px;
    }

    .hard_skill_counter {
        font-size: 20px;
    }

    .client_img {
        margin-right: 10px;
    }

    .nunc,
    .integer {
        text-align: center;
    }

    .viverra {
        text-align: center;
    }

    .logo_design {
        font-size: 18px;
        line-height: 27px;
        padding-bottom: 5px;
    }

    .sidenav a {
        padding: 12px 0;
        font-size: 18px;
    }

    .plan_box_sub {
        padding: 15px;
        gap: 10px;
        text-align: center;
    }

    .service_box {
        text-align: center;
        padding: 15px;
    }

    .ui_ux {
        font-size: 20px;
        padding: 10px 0 5px 0;
    }

    .circle-chart {
        width: 120px;
        height: 120px;
    }

    .mfp-content {
        width: auto;
        min-width: 100%;
        min-height: 400px;
    }

    .city_gallery {
        font-size: 20px;
        padding: 10px 0;
        margin: 0;
    }

    .popup_gallery .close {
        position: absolute;
        top: 0px;
        right: 15px;
    }
}

@media(max-width:415px) {
    .share2 {
        display: none;
    }
}

@media(max-width:390px) {
    .imgBox video {
        height: 425px;
        width: 260px;
    }

    .imgBox {
        height: 425px;
        width: 260px;
    }

    .share-button.share .social.active.top {
        transform: scale(1) translateY(-60px);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .toggle {
        position: fixed;
        top: 20px;
        left: 0px;
        transform: translate(30%, 0px);
    }

    .ul-header {
        position: fixed;
        right: 20px;
        top: 30px;
    }
}